<template>
  <a-row :gutter="25">
    <!--{{ "dataForm "  +  JSON.stringify(dataForm.dataTableInfo) }}-->
    <a-col :xxl="24" :lg="24" :md="24" :xs="24" v-if="!dataForm.showTableByRequest">
      <a-spin size="small" style="position:relative; left: 45%;margin-top: 2rem;" />
    </a-col>
    <a-col :xxl="24" :lg="24" :md="24" :xs="24" v-if="dataForm.showTableByRequest">
      <Suspense>
        <template #default>
          <sdCards>
            <a-row>
              <a-col :xxl="24" :lg="24" :md="24" :xs="24">
                <a-input
                  style="width: 20rem; float:right;"
                  class="title-normal input-field mr-4"
                  placeholder="Buscar evento"
                  v-on:keyup.enter="handleChangeSearch($event)"
                >
                  <template #prefix>
                    <sdFeatherIcons type="search" size="14" />
                  </template>
                </a-input>
              </a-col>
            </a-row>
            <a-row>
              <a-col :xxl="24" :lg="24" :md="24" :xs="24">
                <DataTable
                  :columns="eventsTableColumns"
                  :data="dataForm.dataTableInfo"
                  :totalRecords="propsPaginationDataTable.totalRecords"
                  v-on:getCurrentPaginator="getDataFromPaginatorNumber"
                />
              </a-col>
            </a-row>
          </sdCards>
         </template>
      </Suspense>
    </a-col>
  </a-row>
</template>
<script>
import { reactive, defineAsyncComponent, h } from 'vue';
import { useRouter } from 'vue-router';
import { getEventsOnValidation } from '@/services/events.js';

const DataTable = defineAsyncComponent(() => import('../../components/DataTable/DataTable.vue'));

const ValidationEvents = {
  name: 'ValidationEvents',
  components: {
    DataTable,
  },
  props: {
    generateReport: null,
  },
  async setup() {
    const router = useRouter();

    var propsPaginationDataTable = reactive({
      totalRecords: 0,
    });

    const eventsTableColumns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Nombre del evento',
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: 'Usuario que creo el evento',
        dataIndex: 'creator_name',
        key: 'creator_name',
      },
      {
        title: 'Registro del evento',
        dataIndex: 'created_date',
        key: 'created_date',
      },
      {
        title: 'Estatus',
        dataIndex: 'status',
        key: 'status',
        customRender({ record }) {
          const style = {
            background: 'red',
            width: '0.5rem',
            height: '0.5rem',
            borderRadius: '25px',
            marginRight: '1rem',
            position: 'relative',
            top: '10px',
            right: '12px',
          };
          switch (record.status) {
            case 'Por validar':
              style.background = '#F9C647';
              break;
            case 'No validado':
              style.background = '#E8394D';
              break;
            case 'Activo':
              style.background = '#51AF2C';
              break;
            case 'Finalizado':
              style.background = '#3FA7E1';
              break;
          }
          return [
            h(
              'div',
              {
                style: style,
              },
              [''],
            ),
            h(
              'span',
              {
                style: { position: 'relative', bottom: '4px' },
              },
              [record.status],
            ),
          ];
        },
      },
      {
        title: 'Ver detalle',
        dataIndex: 'actions',
        key: 'actions',
        customRender({ record, index }) {
          return [
            //INICIO::button 1
            h(
              'button',
              {
                onClick: () => onRowButtonClick(record, index),
                style: { background: 'none', cursor: 'pointer', border: 'none' },
              },
              [
                '',
                h('img', {
                  src: require(`../../../static/img_events/SEJ_Eventos_detalles.svg`),
                  style: { width: '22px' },
                }),
              ],
            ),
            //FIN::button 1
          ];
        },
      },
    ];

    const getEventsOnValidationFunc = async (numberPage, search = null) => {
      let events = [];
      await getEventsOnValidation(numberPage, search)
        .then(response => {
          if (response.success) {
            events = generateObjectDataTable(JSON.parse(JSON.stringify(response.events.data)));
            propsPaginationDataTable.totalRecords = response.events.total;
          }
        })
        .catch(() => {});
      return events;
    };

    const formatEventStatus = (status) => {
      switch (status) {
        case 'Por aprobar':
          return 'Por validar';
        case 'No aprobado':
          return 'No validado';
        case 'Aprobado':
          return 'Activo';
        default:
          return status;
      }
    }

    const generateObjectDataTable = events => {
      let data = [];
      data = events.map(element => {
        return {
          key: element.id,
          id: element.id,
          title: element.title.slice(0, 20),
          creator_name: element.creator_name,
          created_date: element.created_date,
          status: formatEventStatus(element.status),
        };
      });
      return data;
    };

    const onRowButtonClick = event => {
      router.push({ path: `/validation/${event.id}` });
    };

    const handleChangeSearch = async input => {
      const value = input.target.value;
      dataForm.searchText = value;
      if (value != '') {
        let events = [];
        await getEventsOnValidation(1, value)
          .then(response => {
            if (response.success) {
              events = generateObjectDataTable(JSON.parse(JSON.stringify(response.events.data)));
              propsPaginationDataTable.totalRecords = response.events.total;
              dataForm.dataTableInfo = events;
            }
          })
          .catch(() => {});
      } else {
        let events = [];
        await getEventsOnValidation(1)
          .then(response => {
            if (response.success) {
              events = generateObjectDataTable(JSON.parse(JSON.stringify(response.events.data)));
              propsPaginationDataTable.totalRecords = response.events.total;
              dataForm.dataTableInfo = events;
            }
          })
          .catch(() => {});
      }
    };

    const getDataFromPaginatorNumber = async number => {
      if (dataForm.searchText != '') {
        dataForm.dataTableInfo = await getEventsOnValidationFunc(number, dataForm.searchText);
      } else {
        dataForm.dataTableInfo = await getEventsOnValidationFunc(number);
      }
    };

    var dataForm = reactive({
      showTableByRequest: true,
      searchText: '',
      dataTableInfo: await getEventsOnValidationFunc(1),
    });

    return {
      dataForm,
      propsPaginationDataTable,
      eventsTableColumns,
      generateObjectDataTable,
      getDataFromPaginatorNumber,
      handleChangeSearch,
    };
  },
};

export default ValidationEvents;
</script>
<style scoped>
.input-field {
  border-radius: 30px;
}
</style>
